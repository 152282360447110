<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Editar Fornecedor
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Tipo"
            label-for="service-type"
          >
            <b-form-select
              id="service-type"
              v-model="item.service_option_id"
              :state="item.service_option_id === null ? false : true"
              :options="serviceOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione um tipo!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Valor Unitário"
            label-for="unit_price"
          >
            <b-form-input
              id="unit_price"
              v-model="item.unit_price"
            />
          </b-form-group>

          <b-form-group
            label="Custos Extras"
            label-for="extra_costs"
          >
            <b-form-input
              id="extra_costs"
              v-model="item.extra_costs"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption
  },
  directives: {
    Ripple,
    mask
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    serviceOptions: {
      type: Array,
      required: true
    }
  },
  methods: {
    clearForm () {
      //
    },
    async handleSubmit () {
      const url = `/admin/supplier_services/${this.item.id}`
      const params = {
        service_option_id: this.item.service_option_id,
        unit_price: this.item.unit_price,
        extra_costs: this.item.extra_costs
      }

      this.$http.patch(url, params).finally(() => {
        this.item.option = this.serviceOptions.find(item => item.value === this.item.service_option_id).option
        this.$emit('updated', this.item)
        this.$emit('update:visible', false)

        this.$swal({
          title: 'Atualizado com sucesso!!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false
        })
      })
    }
  }
}
</script>
