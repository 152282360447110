export default {
  data: () => ({
    loading: true,
    createModal: false,
    editModal: false,
    serviceOptions: [],
    item: {}
  }),
  async mounted () {
    await this.getSuppliers()
    await this.getOptions()
  },
  methods: {
    async getSuppliers () {
      const supplierId = this.$router.currentRoute.params.id
      await this.$http
        .get(`/admin/supplier_services/find_per_supplier_id/${supplierId}`)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
          this.totalRows = this.items.length
        })
    },
    async getOptions () {
      await this.$http
        .get('/admin/options_available_for_suppliers')
        .then(res => {
          this.serviceOptions = res.data.map(item => {
            return {
              value: item.id,
              text: item.name,
              option: { ...item }
            }
          })
        })
    },
    open (id) {
      this.item = JSON.parse(
        JSON.stringify(this.items.find(item => item.id === id))
      )
      this.editModal = true
    },
    created (payload) {
      this.items.push(payload)
    },
    updated (payload) {
      Object.assign(
        this.items.find(item => item.id === payload.id),
        payload
      )
    },
    deleteModal (item) {
      this.$swal({
        title: `Você tem certeza que deseja deletar o serviço "${item.option.name}"?`,
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.delete(item.id)
        }
      })
    },
    async delete (id) {
      this.loading = true
      const url = `/admin/supplier_services/${id}`
      await this.$http
        .delete(url)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deletado!',
            text: 'O Fornecedor selecionado foi deletado com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })

          this.items = this.items.filter(item => item.id !== id)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
